<template>
    <div class="module doughnut-chart-module">
        <BlockHeader class="draggable" title="Asset Trades">
            <ChartToggler v-model="showChart" />
        </BlockHeader>
        <div class="module-content">
            <div class="chart-container" v-show="showChart">
                <doughnut-chart
                  :chart-data="data"
                  :tooltip-label-callback="tooltipLabelCallback"
                  :tooltip-label-color-callback="tooltipLabelColorCallback"
                  v-if="isDataAvailable"
                  :layout-paddings="layoutPaddings"
                  :style="styles"
                />
                <EmptyBarChart v-else />
            </div>

            <div
                v-show="!showChart"
                :class="s.relative"
            >
                <DoughnutChartDatasetList
                    v-if="statisticsArray.length > 0"
                    :items="statisticsArray"
                >
                    <template #item-title="slotProps">
                    <span :style="`color: ${slotProps.itemData.style.base.color}`">
                        {{ slotProps.itemData.base }}
                    </span>
                        /
                        <span :style="`color: ${slotProps.itemData.style.quote.color}`">
                        {{ slotProps.itemData.quote }}
                    </span>
                    </template>
                    <template #item-value="slotProps">
                    <span>
                        {{ quotationAssetCharacter }}
                    </span>
                        {{ slotProps.itemData.quantity }}
                    </template>
                    <template #item-percentage="slotProps"> {{ slotProps.itemData.part }}% </template>
                </DoughnutChartDatasetList>
                <NoData v-else />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { timeConstants } from 'Config/timeConstants';
import { getManifest } from 'Models/assetsManifest';
import BlockHeader from 'UI/BlockHeader.vue';
import ChartToggler from 'UI/ChartToggler.vue';
import { showLabelThreshold } from 'Config/portfolio';
import { PORTFOLIO_TYPES } from 'Models/portfolio';
import NoData from 'UI/NoData.vue';

import DoughnutChartDatasetList from './components/DoughnutChartDatasetList.vue';
import TradesPerAssetPairDonutData from './TradesPerAssetPairDonut.Data.vue';

const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

export default Vue.extend({
    name: 'TradesPerAssetPair',
    components: {
        NoData,
        DoughnutChartDatasetList,
        BlockHeader,
        ChartToggler,
    },
    mixins: [TradesPerAssetPairDonutData],
    props: {
        range: {
            type: Object,
            default: () => ({
                start: new Date(Date.now() - timeConstants.MONTH),
                end: new Date(),
            }),
        },
        module: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            styles: {
                width: '300px',
            },
            layoutPaddings: {
                left: 90,
                right: 90,
                top: 0,
                bottom: 60,
            },

            showChart: true,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            darkTheme: 'isThemeDark',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        portfolioType() {
            return this.$store.state.Portfolio.portfolioType;
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        statisticsArray() {
            return Object.keys(this.tradesPerAssetPair)
                .map((assetPair) => {
                    const base = assetPair.split('/')[0];
                    const quote = assetPair.split('/')[1];
                    const quantity = (+this.tradesPerAssetPair[assetPair]).toFixed(this.quotationAssetPrecision);
                    return {
                        // transfer to a new line in case of a long record
                        assetPair: assetPair.split(' ').join('\n'),
                        base,
                        quote,
                        quantity,
                        part: this.totalValue !== 0 ? ((quantity / this.totalValue) * 100).toFixed(2) : quantity.toFixed(2),
                        style: {
                            base: getManifest(base),
                            quote: getManifest(quote),
                        },
                    };
                })
                .sort((a, b) => b.quantity - a.quantity);
        },

        isDataAvailable() {
            return this.statisticsArray.length > 0;
        },
        labels() {
            return this.statisticsArray.map(({ assetPair }) => assetPair);
        },
        datasetData() {
            return this.statisticsArray.map(({ quantity }) => quantity);
        },
        totalValue() {
            return Object.values(this.tradesPerAssetPair)
                .reduce((sum, current) => sum + +current, 0)
                .toFixed(this.quotationAssetPrecision);
        },
        isDisplay() {
            let currentPart = 0;
            const isDisplay = this.datasetData.map((_, index) => index === 0);

            for (let i = 1; i < isDisplay.length; i++) {
                if (+this.statisticsArray[i].part > showLabelThreshold) {
                    isDisplay[i] = true;
                } else if (+this.statisticsArray[i - 1].part > showLabelThreshold) {
                    isDisplay[i] = true;
                } else {
                    currentPart += +this.statisticsArray[i].part;

                    if (currentPart >= showLabelThreshold) {
                        isDisplay[i] = true;
                        currentPart = 0;
                    }
                }
            }
            return isDisplay;
        },
        backgroundColor() {
            return this.statisticsArray.map(({ style: { base, quote } }) => {
                const chartMiddleX = 150;
                const chartMiddleY = 120;
                const gradient = ctx.createRadialGradient(
                    chartMiddleX,
                    chartMiddleY,
                    0,
                    chartMiddleX,
                    chartMiddleY,
                    chartMiddleX,
                );

                gradient.addColorStop(0.29, quote.color);
                gradient.addColorStop(0.3, base.color);

                return gradient;
            });
        },
        borderColor() {
            return this.darkTheme ? '#2C2C36' : 'white';
        },
        outlabels() {
            return {
                baseAsset: {
                    display: true,
                    text: ({ dataset, dataIndex }) => `${dataset.config[dataIndex].base}\n${this.getEmptyString(dataset.config[dataIndex].quote)}\n`,
                },
                slash: {
                    display: ({ dataIndex }) => this.isDisplay[dataIndex],
                    text: ({ dataset, dataIndex }) => `${this.getEmptyString(dataset.config[dataIndex].base, true)}/${this.getEmptyString(
                        dataset.config[dataIndex].quote,
                        true,
                    )}\n`,
                },
                quoteAsset: {
                    display: ({ dataIndex }) => this.isDisplay[dataIndex],
                    text: ({ dataset, dataIndex }) => `${this.getEmptyString(dataset.config[dataIndex].base)}\n${this.getEmptyString(
                        dataset.config[dataIndex].quote,
                    )}\n`,
                },
                value: {
                    display: ({ dataIndex }) => this.isDisplay[dataIndex],
                    text: ({ dataset, dataIndex }) => `${this.getEmptyString(dataset.config[dataIndex].base, true)}${this.getEmptyString(
                        dataset.config[dataIndex].quote,
                        true,
                    )}\n${dataset.config[dataIndex].part}%`,
                },
            };
        },
        summaryBalance() {
            return this.datasetData.reduce((prev, next) => prev + Number(next), 0);
        },
        valuableBalancesNumber() {
            const result = this.datasetData.reduce((prev, next) => {
                if ((next / this.summaryBalance) * 100 >= 5) {
                    return prev + 1;
                }
                return prev;
            }, 0);
            return result > 9 ? 9 : result;
        },
        truncatedLabels() {
            return this.labels.map((label, index) => {
                if (index !== this.valuableBalancesNumber) {
                    return label;
                }
                return 'Other';
            }).filter((_, index) => index <= this.valuableBalancesNumber);
        },
        truncatedDatasetData() {
            const truncatedDatasetData = [];
            this.datasetData.forEach((el, index) => {
                if (index < this.valuableBalancesNumber) {
                    truncatedDatasetData.push(Number(el));
                } else if (!truncatedDatasetData[this.valuableBalancesNumber]) {
                    truncatedDatasetData.push(Number(el));
                } else {
                    truncatedDatasetData[this.valuableBalancesNumber] += Number(el);
                }
            });
            return truncatedDatasetData;
        },
        data() {
            return {
                labels: this.truncatedLabels,
                datasets: [
                    {
                        borderColor: this.borderColor, // 'transparent', // Unknown bug, when subscrube to this.darkTheme
                        backgroundColor: this.backgroundColor,
                        borderWidth: 1,
                        config: this.statisticsArray,
                        data: this.truncatedDatasetData,
                        outlabels: this.outlabels,
                    },
                ],
            };
        },
    },
    methods: {
        getEmptyString(asset, isSlash) {
            let result = '';

            if (asset !== undefined) {
                for (let i = 0; i < asset.length; i++) {
                    result += '   ';
                }

                result += isSlash ? '' : '  ';
            }

            return result;
        },
        tooltipLabelCallback({ datasetIndex, index }, { datasets }) {
            return `${this.truncatedLabels[index]}: ${this.quotationAssetCharacter}${datasets[datasetIndex].data[
                index
            ].getSeparatedDigits()}`;
        },
        tooltipLabelColorCallback({ datasetIndex, index }, { data: { datasets } }) {
            return {
                borderColor: datasets[datasetIndex].config[index].style.quote.color,
                backgroundColor: datasets[datasetIndex].config[index].style.base.color,
            };
        },
    },
    created() {
        if (!(this.activeAccount && this.range)) {
            return;
        }
        this.getData(this.range, this.module);
    },
    watch: {
        range() {
            if (!(this.activeAccount && this.range)) {
                return;
            }
            this.getData(this.range, this.module);
        },
        activeAccount() {
            if (!(this.activeAccount && this.range)) {
                return;
            }
            this.getData(this.range, this.module);
        },
        portfolioType() {
            if (!this.module && (this.activeAccount && this.range)) {
                this.getData(this.range, this.portfolioType === PORTFOLIO_TYPES.CURRENT_ACCOUNT);
            }
        },
    },
});

</script>

<style lang="postcss" module="s">
.relative {
    position: relative;
}
</style>
