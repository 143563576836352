<template>
    <div class="doughnut-chart-module__dataset-list">

        <table>
            <tbody>

            <tr v-for="item in items"
                :key="item.id"
            >
                <td>
                    <slot name="item-title"
                          :item-data="item"
                    >
                    </slot>
                </td>

                <td class="is-transparent">
                    <slot name="item-value"
                          :item-data="item"
                    >
                    </slot>
                </td>

                <td>
                    <slot name="item-percentage"
                          :item-data="item"
                    ></slot>
                </td>
            </tr>

            </tbody>
        </table>

    </div>
</template>

<script>
export default {
    name: 'PortfolioDoughnutChartDatasetList',
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
};
</script>
