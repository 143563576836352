<template>
    <div class="module doughnut-chart-module">
        <BlockHeader class="draggable" title="Current Allocation">
            <ChartToggler v-model="showChart" />
        </BlockHeader>
        <div :class="['module-content', { 'is-overflowed': !showChart }]">
            <div
                v-show="!showChart"
                :class="s.relative"
            >
                <DoughnutChartDatasetList
                    v-if="placementsBalances.length > 0"
                    :items="placementsBalances"
                >
                    <template #item-title="slotProps">
                        <div :class="s.row">
                            <PlacementIcon :placement="slotProps.itemData.placementName" />
                            <span>
                            {{ slotProps.itemData.placementName }}
                        </span>
                        </div>
                    </template>
                    <template #item-value="slotProps">
                        <span>{{ quotationAssetCharacter }}</span>{{ slotProps.itemData.totalEquivalent.round(quotationAssetPrecision).getSeparatedDigits() }}
                    </template>
                    <template #item-percentage="slotProps"> {{ slotProps.itemData.part }}%</template>
                </DoughnutChartDatasetList>
                <NoData v-else />
            </div>
            <div class="chart-container" v-show="showChart">
                <doughnut-chart
                    :chart-data="data"
                    :tooltip-label-callback="tooltipLabelCallback"
                    v-if="isDataAvailable"
                    :layout-paddings="layoutPaddings"
                    :style="styles"
                />
                <EmptyBarChart v-else/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getPlacementStyle } from 'Config/placementsManifest';
import { showLabelThreshold } from 'Config/portfolio';
import BlockHeader from 'UI/BlockHeader.vue';
import ChartToggler from 'UI/ChartToggler.vue';
import { PORTFOLIO_TYPES } from 'Models/portfolio';
import NoData from 'UI/NoData.vue';

import DoughnutChartDatasetList from '../../Portfolios/components/DoughnutChartDatasetList.vue';

export default {
    name: 'TradesPerExchange',
    components: {
        NoData,
        DoughnutChartDatasetList,
        BlockHeader,
        ChartToggler,
    },
    data() {
        return {
            styles: {
                width: '300px',
            },
            layoutPaddings: {
                left: 90,
                right: 90,
                top: 0,
                bottom: 60,
            },

            showChart: true,
        };
    },
    computed: {
        ...mapGetters({
            balancesByPlacements: 'Balances/GET_BALANCES_BY_PLACEMENTS',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        portfolioType() {
            return this.$store.state.Portfolio.portfolioType;
        },
        totalScore() {
            if (this.portfolioType === PORTFOLIO_TYPES.CURRENT_ACCOUNT) {
                return this.$store.getters['Balances/GET_ACTIVE_ACCOUNT_TOTAL_SCORE'];
            }
            return this.$store.getters['Portfolio/requestAccountsData'].accountIds
                .reduce((accum, id) => accum + this.$store.getters['Balances/GET_TOTAL_ACCOUNT_SCORE'](id), 0);
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        placementsBalances() {
            return new Array(...this.balancesByPlacements)
                .filter((balance) => balance.totalEquivalent > 0)
                .sort((a, b) => b.totalEquivalent - a.totalEquivalent)
                .map((balance) => Object.assign(balance, {
                    placementStyle: getPlacementStyle(balance.placementName),
                    part:
              this.totalScore !== 0
                  ? ((balance.totalEquivalent / this.totalScore) * 100).toFixed(2)
                  : balance.totalEquivalent.toFixed(2),
                }));
        },

        isDataAvailable() {
            return this.placementsBalances.length > 0;
        },

        chartLabels() {
            return this.placementsBalances.map(({ placementName }) => placementName);
        },
        chartDatasetData() {
            return this.placementsBalances.map(({ totalEquivalent }) => totalEquivalent);
        },
        data() {
            const isDisplay = this.chartDatasetData.map((_, index) => index === 0);
            let currentPart = 0;

            for (let i = 1; i < isDisplay.length; i++) {
                if (+this.placementsBalances[i].part > showLabelThreshold) {
                    isDisplay[i] = true;
                } else if (+this.placementsBalances[i - 1].part > showLabelThreshold) {
                    isDisplay[i] = true;
                } else if (i !== isDisplay.length - 1) {
                    currentPart += +this.placementsBalances[i].part;

                    if (currentPart >= showLabelThreshold) {
                        isDisplay[i] = true;
                        currentPart = 0;
                    }
                }
            }

            const summaryBalance = this.chartDatasetData.reduce((prev, next) => prev + Number(next), 0);
            let valuableBalancesNumber = this.chartDatasetData.reduce((prev, next) => {
                if ((next / summaryBalance) * 100 >= 5) {
                    return prev + 1;
                }
                return prev;
            }, 0);
            if (valuableBalancesNumber > 9) {
                valuableBalancesNumber = 9;
            }
            const truncatedLabels = this.chartLabels.map((label, index) => {
                if (index !== valuableBalancesNumber) {
                    return label;
                }
                return 'Other';
            }).filter((_, index) => index <= valuableBalancesNumber);
            const truncatedDatasetData = [];
            this.chartDatasetData.forEach((el, index) => {
                if (index < valuableBalancesNumber) {
                    truncatedDatasetData.push(Number(el));
                } else if (!truncatedDatasetData[valuableBalancesNumber]) {
                    truncatedDatasetData.push(Number(el));
                } else {
                    truncatedDatasetData[valuableBalancesNumber] += Number(el);
                }
            });

            return {
                labels: truncatedLabels,
                datasets: [
                    {
                        backgroundColor: this.placementsBalances.map(({ placementStyle }) => placementStyle.theme_colors.main),
                        hoverBackgroundColor: this.placementsBalances.map(
                            ({ placementStyle }) => placementStyle.theme_colors.lighter,
                        ),
                        borderWidth: 1,
                        borderColor: this.$store.getters.isThemeDark ? '#2C2C36' : 'white',
                        data: truncatedDatasetData,
                        config: {
                            part: this.placementsBalances.map(({ part }) => part),
                        },
                        outlabels: {
                            display: true,
                            text: ({ labels, dataset, dataIndex }) => `${labels[dataIndex]}\n${dataset.config.part[dataIndex]}%`,
                        },
                        // datalabels: {
                        //   labels: {
                        //     title: {
                        //       anchor: 'end',
                        //       align: 'end',
                        //       textAlign: 'center',
                        //       color: ({ dataIndex, dataset }) => {
                        //         return dataset.backgroundColor[dataIndex];
                        //       },
                        //       display: ({ dataIndex }) => {
                        //         return isDisplay[dataIndex];
                        //       },
                        //       formatter: (_, { dataIndex, dataset }) => {
                        //         return `${this.chartLabels[dataIndex]}\n${dataset.config.part[dataIndex]}%`;
                        //       },
                        //     },
                        //   },
                        // },
                    },
                ],
            };
        },
    },
    methods: {
        tooltipLabelCallback({ index }, { datasets: [{ data }], labels }) {
            const currentLabel = labels[index];

            return `${currentLabel}: ${this.quotationAssetCharacter}${data[index]
                .toFixed(this.quotationAssetPrecision)
                .getSeparatedDigits()}`;
        },
    },
};
</script>

<style lang="postcss" module="s">
.row {
    display: flex;
    align-items: center;
    column-gap: var(--m-xs);
}
.relative {
    position: relative;
}
</style>
