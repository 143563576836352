
import Vue from 'vue';

import PortfolioApi from 'Apis/Portfolio';
import TradePayload from 'Entities/portfolioBalanceHistory/TradePayload';

interface Data {
    tradesPerAssetPair: Record<string, number>,
}

export default Vue.extend<Data, any, any>({
    data() {
        return {
            tradesPerAssetPair: {},
        };
    },
    methods: {
        async getData(range, module) {
            const { start: from, end: to } = range;
            const { policies } = this.$store.getters['Accounts/activeAccount'];
            if (policies.includes('portfolio')) {
                const { data } = await PortfolioApi.getTrades(new TradePayload({
                    groupBy: 'AssetPair',
                    UTCFromTs: from.valueOf(),
                    UTCToTs: to.valueOf(),
                    ...(module
                        ? { accountId: this.$store.getters['Accounts/activeAccountID'] }
                        : this.$store.getters['Portfolio/requestAccountsData']
                    ),
                    quotationAssetSymbol: this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
                }));
                const result = {};
                Object.keys(data)
                    .forEach((assetPair) => {
                        result[assetPair] = data[assetPair].tradeVolumeList?.reduce<number>((sum, current) => sum + +current, 0);
                    });
                this.tradesPerAssetPair = result;
            }
        },
    },
});
