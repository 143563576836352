<template>
    <div
        :class="{'disabled': disabled}"
        class="selectbox rounded multiply assets-selectbox"
        tabindex="0"
        @click="show = !show"
    >
        <div>
            <p class="current-item">
                {{ title }}
            </p>
            <span class="arrow-down">
                <img src="@/assets/images/icons/ArrowDown_light.svg" alt="arrow down" v-if="$store.getters.isThemeDark"/>
                <img src="@/assets/images/icons/ArrowDownDark.svg" alt="arrow down" v-else/>
            </span>
        </div>
        <ul
            v-if="!disabled && show"
            :class="['items-list', `align-${ dropdownAlign }`]"
            @click.stop
        >
            <li
                v-for="(item) in items"
                :key="item.id"
            >
                <label
                    class="checkbox"
                    style="width: 100%; height: 100%;"
                >
                    <input
                        type="checkbox"
                        class="checkbox__input"
                        :value="item"
                        v-model="currentValue"
                    />
                    <slot
                        name="list-label"
                        :itemData="item"
                    >
                        <span>
                            {{ item }}
                        </span>
                    </slot>
                </label>
            </li>
        </ul>
    </div>
</template>

<script>
import { composedPath } from 'Lib/utils/eventPathChecker';

export default {
    name: 'MultiplySelectBox',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        value: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dropdownAlign: {
            type: String,
            default: 'left',
            validation: (value) => ['left', 'right'].indexOf(value) !== -1,
        },
    },
    data() {
        return {
            show: false,
            currentValue: [],
        };
    },
    methods: {
        change() {
            this.$emit('input', this.currentValue);
            this.$emit('change', this.currentValue);
        },
        isChecked(item) {
            return this.currentValue.indexOf(item) !== -1;
        },
        toggleItem(item, value) {
            if (value) {
                this.currentValue.push(item);
            } else if (this.currentValue.indexOf(item) !== -1) {
                this.currentValue.splice(this.currentValue.indexOf(item), 1);
            }
        },
        outsideClickListener(e) {
            const path = composedPath(e.target);
            if (!path.some((node) => node.classList && Array.from(node.classList).includes('assets-selectbox'))) {
                this.show = false;
            }
        },
    },
    mounted() {
        if (this.allChecked) {
            this.currentValue = this.items;
        }
        document.addEventListener('click', this.outsideClickListener);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.outsideClickListener);
    },
    watch: {
        value() {
            this.currentValue = this.value;
        },
        currentValue() {
            this.change();
        },
    },
};
</script>
