<template>
  <ToggleBar symbol-off="0" :value="value" @input="$emit('input', $event)">
        <template #symbol-on>
            <img
                v-if="$store.getters.isThemeDark"
                src="@/assets/images/icons/pie-chart_light.svg"
                alt="crossed out zero"
                :class="s.on"
            />
            <img
                v-else
                src="@/assets/images/icons/pie-chart.svg"
                alt="crossed out zero"
                :class="s.on"/>
        </template>
        <template #symbol-off>
            <img
                v-if="$store.getters.isThemeDark"
                src="@/assets/images/icons/list_light.svg"
                alt="crossed out zero"
                :class="s.off"
            />
            <img
                v-else
                src="@/assets/images/icons/list.svg"
                alt="crossed out zero"
                :class="s.off"
            />
        </template>
    </ToggleBar>
</template>

<script>

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="postcss" module="s">
    .on {
        margin-bottom: -2px
    }
    .off {
        margin-bottom: -1px;
        margin-right: -1px
    }
</style>
