<template>
    <div :class="s.relative">
        <div
            ref="togglerBlock"
            :class="[s.togglerBlockContainer, { [s.fixed]: !isTogglerBlockVisible, [s.paddingBottom]: !isTogglerBlockVisible, [s.shadow]: !isTogglerBlockVisible }]"
        >
            <TogglerBlock />
        </div>
        <PageLayout>
            <template #desktop-view>
                <grid-layout
                    :layout="layout"
                    :col-num="4"
                    :row-height="90"
                    :responsive="true"
                    :breakpoints="vueGridLayoutBreakpoints"
                    :cols="vueGridLayoutCols"
                    :is-draggable="true"
                    :is-resizable="false"
                    :vertical-compact="true"
                    :margin="[14, 14]"
                    :use-css-transforms="false"
                    @breakpoint-changed="breakpointChangedEvent"
                >
                    <grid-item
                        :x="getLayoutElementByName(layout, 'BalanceByCurrency').x"
                        :y="getLayoutElementByName(layout, 'BalanceByCurrency').y"
                        :w="getLayoutElementByName(layout, 'BalanceByCurrency').w"
                        :h="getLayoutElementByName(layout, 'BalanceByCurrency').h"
                        :i="getLayoutElementByName(layout, 'BalanceByCurrency').i"
                        drag-allow-from=".draggable"
                    >
                        <BalanceByCurrency
                            id="balanceByCurrencyModule"
                        />
                    </grid-item>

                    <grid-item
                        :x="getLayoutElementByName(layout, 'BalanceByExchange').x"
                        :y="getLayoutElementByName(layout, 'BalanceByExchange').y"
                        :w="getLayoutElementByName(layout, 'BalanceByExchange').w"
                        :h="getLayoutElementByName(layout, 'BalanceByExchange').h"
                        :i="getLayoutElementByName(layout, 'BalanceByExchange').i"
                        drag-allow-from=".draggable"
                    >
                        <BalanceByExchange />
                    </grid-item>

                    <grid-item
                        :x="getLayoutElementByName(layout, 'TradesPerAssetPair').x"
                        :y="getLayoutElementByName(layout, 'TradesPerAssetPair').y"
                        :w="getLayoutElementByName(layout, 'TradesPerAssetPair').w"
                        :h="getLayoutElementByName(layout, 'TradesPerAssetPair').h"
                        :i="getLayoutElementByName(layout, 'TradesPerAssetPair').i"
                        drag-allow-from=".draggable"
                    >
                        <TradesPerAssetPair :range="range"/>
                    </grid-item>

                    <grid-item
                        :x="getLayoutElementByName(layout, 'TradesPerExchange').x"
                        :y="getLayoutElementByName(layout, 'TradesPerExchange').y"
                        :w="getLayoutElementByName(layout, 'TradesPerExchange').w"
                        :h="getLayoutElementByName(layout, 'TradesPerExchange').h"
                        :i="getLayoutElementByName(layout, 'TradesPerExchange').i"
                        drag-allow-from=".draggable"
                    >
                        <TradesPerExchange
                            :range="range"
                        />
                    </grid-item>

                    <grid-item
                        :x="getLayoutElementByName(layout, 'PNLChart').x"
                        :y="getLayoutElementByName(layout, 'PNLChart').y"
                        :w="getLayoutElementByName(layout, 'PNLChart').w"
                        :h="getLayoutElementByName(layout, 'PNLChart').h"
                        :i="getLayoutElementByName(layout, 'PNLChart').i"
                        drag-allow-from=".draggable"
                    >
                        <PNLChart id="pnlHistoryModule" :range="range"/>
                    </grid-item>

                    <grid-item
                        :x="getLayoutElementByName(layout, 'BalanceByPeriod').x"
                        :y="getLayoutElementByName(layout, 'BalanceByPeriod').y"
                        :w="getLayoutElementByName(layout, 'BalanceByPeriod').w"
                        :h="getLayoutElementByName(layout, 'BalanceByPeriod').h"
                        :i="getLayoutElementByName(layout, 'BalanceByPeriod').i"
                        drag-allow-from=".draggable"
                    >
                        <BalanceByPeriod id="balanceHistoryModule" :range="range"/>
                    </grid-item>

                    <grid-item
                        :x="getLayoutElementByName(layout, 'AggregatedBalance').x"
                        :y="getLayoutElementByName(layout, 'AggregatedBalance').y"
                        :w="getLayoutElementByName(layout, 'AggregatedBalance').w"
                        :h="getLayoutElementByName(layout, 'AggregatedBalance').h"
                        :i="getLayoutElementByName(layout, 'AggregatedBalance').i"
                        drag-allow-from=".draggable"
                    >
                        <AgregatedBalances id="portfolioAggregatedBalanceModule" :show-short-graph="true"/>
                    </grid-item>
                </grid-layout>
            </template>
        </PageLayout>
        <ModuleBlocker v-if="!hasPortfoliosAccess" is-page-blocker />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PageLayout from 'Common/PageLayout.vue';
import AgregatedBalances from 'Modules/AgregatedBalances/AgregatedBalances.vue';
import { gridPages } from 'Models/interface';
import layoutNameResolver from 'Mixins/layoutNameResolver';
import BalanceByCurrency from 'Modules/BalanceByCurrency/BalanceByCurrency.vue';
import BalanceByExchange from 'Modules/BalanceByExchange/BalanceByExchange.vue';
import BalanceByPeriod from 'Modules/BalanceByPeriod/BalanceByPeriod.vue';
import TradesPerAssetPair from 'Modules/TradesPerAssetPairDonut/TradesPerAssetPairDonut.vue';
import TradesPerExchange from 'Modules/TradesPerExchangeDonut/TradesPerExchangeDonut.vue';
import PNLChart from 'Modules/PNLChart/PNLChart.vue';
import ModuleBlocker from 'UI/ModuleBlocker.vue';

import TogglerBlock from './components/TogglerBlock.vue';

export default {
    name: 'Portfolios',
    mixins: [
        layoutNameResolver,
    ],
    components: {
        PageLayout,
        TogglerBlock,
        BalanceByPeriod,
        BalanceByCurrency,
        BalanceByExchange,
        TradesPerAssetPair,
        TradesPerExchange,
        AgregatedBalances,
        PNLChart,
        ModuleBlocker,
    },
    data() {
        return {
            dragAllowFrom: '.module-header, .vue-grid-item-drag-target',
            currentBreakpoint: 'lg',
            isTogglerBlockVisible: true,
        };
    },
    computed: {
        ...mapGetters({
            vueGridLayoutBreakpoints: 'vueGridLayoutBreakpoints',
            vueGridLayoutCols: 'vueGridLayoutCols',
            activeAccount: 'Accounts/activeAccount',
        }),
        range() {
            return this.$store.state.Portfolio.range;
        },
        layout: {
            get() {
                return this.$store.getters.pageGrid(gridPages.PORTFOLIOS, this.currentBreakpoint);
            },
            set(grid) {
                this.$store.dispatch('changePageGrid', {
                    page: gridPages.PORTFOLIOS,
                    grid,
                    breakpoint: this.currentBreakpoint,
                });
            },
        },
        hasPortfoliosAccess() {
            if (this.activeAccount) {
                return this.activeAccount.policies.includes('portfolio');
            }
            return true;
        },
    },
    methods: {
        breakpointChangedEvent(breakpoint) {
            this.currentBreakpoint = breakpoint;
            this.$store.commit('SET_PAGE_ACTIVE_BREAKPOINT', { page: gridPages.PORTFOLIOS, breakpoint });
        },
        scrollListener() {
            this.isTogglerBlockVisible = document.querySelector('body').scrollTop < 40;
            if (!this.isTogglerBlockVisible) {
                this.$refs.togglerBlock.style.left = `${document.querySelector('aside').getBoundingClientRect().width + 14}px`;
            }
        },
    },
    created() {
        this.$store.dispatch('Portfolio/mountPortfolio');
    },
    destroyed() {
        this.$store.dispatch('Portfolio/removeToggleAccountListener');
    },
    mounted() {
        document.querySelector('body').addEventListener('scroll', this.scrollListener);
    },
    beforeDestroy() {
        document.querySelector('body').removeEventListener('scroll', this.scrollListener);
    },
};
</script>

<style lang="postcss" module="s">
.no-account-access {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    p {
        font-size: var(--fs-l);
        line-height: var(--fs-l);
        width: 100%;
        max-width: 200px;
        text-align: center;
        font-weight: var(--fw-medium);
        color: var(--cl-gray);
        margin-top: var(--m-xxxl);
    }
}
.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}
.relative {
    position: relative;
}
.togglerBlockContainer {
    padding: var(--m-l) 14px 0 14px;
    &.paddingBottom {
        padding-bottom: var(--m-l);
    }

    &.shadow {
        box-shadow: var(--popover-box-shadox);
    }

    &.fixed {
        position: fixed;
        top: 62px;
        right: 20px;
        z-index: 998;
        background-color: var(--cl-white-background);
        outline: 1px solid var(--cl-violet);
        border-radius: 16px;
    }
}
</style>
